<template>
  <div
    :id="id"
    class="carousel"
  >
    <button
      v-show="currSlide > 0"
      id="carousel-prev"
      class="carousel--ctrl"
      @click="prev"
    >
      <span class="material-icons-outlined">
        chevron_left
      </span>
    </button>

    <div class="tracks">
      <div
        v-for="(slide, index) in slides"
        :key="slide.id"
        class="carousel-cell"
        :style="{ transform: `translateX(${index * 115}%)` }"
        @click="() => selectItem(slide)"
      >
        <div class="item-card">
          <div class="item-card--preview">
            <div
              v-if="!slide.hideHeader"
              class="item-card--header"
            >
              <vs-collapse v-if="slide.info && slide.content.length > 0">
                <vs-collapse-item>
                  <div slot="header">
                    <span
                      class="prvw--title"
                      :style="{ textTransform: capitalizeTitle ? 'capitalize' : 'none' }"
                    >
                      {{ slide.title }}
                    </span>
                  </div>

                  <div class="prvw--info">
                    <span
                      v-for="item in slide.info"
                      :key="item"
                    >
                      {{ item }}
                    </span>
                  </div>
                </vs-collapse-item>
              </vs-collapse>
              <span
                v-else
                class="prvw--title p-2"
                :style="{ textTransform: capitalizeTitle ? 'capitalize' : 'none' }"
              >
                {{ slide.title }}
              </span>
            </div>

            <div
              v-if="slide.cardType === 'image'"
              class="prvw--image"
              @click="selectItem(slide); handleAction('expand');"
            >
              <img
                :src="slide.image"
                alt="Preview indisponível"
              >
            </div>
            <div
              v-else-if="slide.cardType === 'icon'"
              class="prvw--image"
            >
              <span
                class="material-icons-outlined"
                :style="{ color: '#bfbfbf', fontSize: '2.5em' }"
              >
                {{ slide.icon }}
              </span>
            </div>
          </div>

          <div
            v-if="!slide.hideControls"
            class="item-card--controls"
          >
            <vs-tooltip
              v-if="slide.content.length === 0"
              class="w-100"
              delay="0.3s"
              position="top"
              text="Não é possível editar um HTML importado."
            >
              <vs-button
                :disabled="true"
                icon="edit"
                size="small"
                :text-color="colorDark"
                type="flat"
              />
            </vs-tooltip>
            <vs-button
              v-else
              icon="edit"
              size="small"
              :text-color="colorDark"
              type="flat"
              @click="selectItem(slide); handleAction('edit');"
            />

            <vs-button
              icon="delete"
              size="small"
              :text-color="colorDanger"
              type="flat"
              @click="selectItem(slide); handleAction('delete');"
            />
          </div>
        </div>
      </div>
    </div>

    <button
      v-if="slides.length > 6"
      id="carousel-next"
      class="carousel--ctrl"
      @click="next"
    >
      <span class="material-icons-outlined">
        chevron_right
      </span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'Carousel',
  props: {
    id: {
      type: String,
      required: true,
    },
    slides: {
      type: Array,
      default: () => ([]),
    },
    capitalizeTitle: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    currSlide: 0,
  }),
  computed: {
    // cardSubtitleColor() {
    //   return this.$store.state.configBox.bgCardColors;
    // },
    colorDanger() {
      return 'rgb(220, 38, 38)';
    },
    colorDark() {
      return 'rgb(31, 41, 55)';
    },
  },
  methods: {
    /* Controls */
    next() {
      const carousel = document.getElementById(this.id);
      const slides = carousel.querySelectorAll('.carousel-cell');

      const maxSlide = slides.length - 6;

      if (this.currSlide === maxSlide) {
        this.currSlide = 0;
      } else {
        this.currSlide++;
      }

      // Move slide by -100%
      slides.forEach((slide, indx) => {
        slide.style.transform = `translateX(${115 * (indx - this.currSlide)}%)`;
      });
    },
    prev() {
      const carousel = document.getElementById(this.id);
      const slides = carousel.querySelectorAll('.carousel-cell');

      const maxSlide = slides.length - 6;

      if (this.currSlide === 0) {
        this.currSlide = maxSlide;
      } else {
        this.currSlide--;
      }

      // Move slide by 100%
      slides.forEach((slide, indx) => {
        slide.style.transform = `translateX(${115 * (indx - this.currSlide)}%)`;
      });
    },
    /* Templates */
    handleAction(action) {
      if (action === 'expand') {
        this.$emit('call-expand-popup');
      } else if (action === 'delete') {
        this.$emit('delete-item');
      } else if (action === 'edit') {
        this.$emit('edit-item');
      }
    },
    selectItem(item) {
      this.$emit('select-item', item);
    },
  },
};
</script>

<style lang="scss">
.carousel {
  background-color: #f2f0ff;
  border-radius: .4rem;
  height: 300px;
  padding: 1.5rem 0;
  position: relative;
  width: 100%;

  .carousel--ctrl {
    cursor: pointer;
    align-items: center;
    appearance: none;
    background: white;
    border: 1px solid #d8dade;
    border-radius: 50%;
    box-shadow: 0 0 12px 0 rgba(64, 87, 109, .07);
    display: flex;
    height: 40px;
    justify-content: center;
    outline: none;
    top: 42%;
    width: 40px;
    z-index: 99;

    span {
      color: #2f3336;
      font-size: 1.5em;
    }
  }

  #carousel-prev {
    position: absolute;
    left: 4px;
  }

  #carousel-next {
    position: absolute;
    right: 4px;
  }

  .tracks {
    align-items: center;
    display: flex;
    height: 100%;
    padding: 0 1.5rem;
    position: relative;
    overflow-x: hidden;
    width: 100%;

    .carousel-cell {
      align-items: center;
      display: flex;
      width: 9.65rem;
      height: 13.65rem;
      justify-content: center;
      position: absolute;
      transition: all 0.5s;

      .item-card {
        align-items: center;
        background-color: white;
        border-radius: 4px;
        box-shadow:
          rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
          rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
        display: flex;
        flex-flow: column nowrap;
        height: 13.65rem;
        justify-content: space-between;
        width: 9.65rem;

        &:hover {
          background-color: #f3f3f3;
        }

        .item-card--preview {
          border-radius: 4px 4px 0 0;
          position: relative;
          height: 100%;
          width: 100%;

          .prvw--image {
            align-items: center;
            display: flex;
            height: 100%;
            justify-content: center;
            position: absolute;
            top: 0;
            user-select: none;
            cursor: pointer;
            width: 100%;

            img {
              align-items: center;
              display: flex;
              height: 100%;
              justify-content: center;
              line-height: 210px;
              object-fit: contain;
              user-select: none;
              width: 100%;
            }
          }

          .item-card--header {
            align-items: stretch;
            border-radius: 4px 4px 0 0;
            background: linear-gradient(
              0deg, rgba(0, 0, 0, 0) 0%,
              rgba(40, 40, 50, 0.8) 50%,
              rgba(0, 0, 0, 0.5) 100%);
            display: flex;
            flex-flow: column nowrap;
            justify-content: stretch;
            position: absolute;
            top: 0;
            width: 100%;
            position: absolute;
            z-index: 98;

            .vs-collapse-item--header,
            .vs-collapse default,
            .con-content--item  {
              padding: 0;
            }

            .icon-header.vs-collapse-item--icon-header i {
              color: white;
              font-size: 1.25em;
            }

            .prvw--title {
              color: white;
              font-size: 0.85em;
              font-weight: 600;
              z-index: 99;
            }

            .prvw--info {
              align-items: center;
              display: flex;
              flex-flow: column nowrap;
              gap: 0.45rem;
              justify-content: center;
              padding: 0.5rem 0 2rem;
              position: relative;
              width: 100%;

              span {
                color: white;
                font-size: 0.75em;
                font-weight: 600;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 100%;
                z-index: 99;
              }
            }
          }
        }

        .item-card--controls {
          align-items: center;
          display: flex;
          justify-content: stretch;
          width: 100%;

          /* Overwrite Vuesax's button style */
          .includeIconOnly {
            width: 100% !important;
          }
        }
      }
    }
  }
}
</style>
