// Helpers
const { v4: uuidv4 } = require('uuid');
import generateCellByBlockId from '../TemplateBuilder/helpers/generate-cell-by-block-id';

const generateTemplateThree = (blocks) => {
  // Block 1: Header
  const headerRowId = uuidv4();
  const headerBlock = generateCellByBlockId(blocks, 'Cabeçalho', 1);

  const headerRow = {
    id: headerRowId,
    cells: [headerBlock],
    fixed: true,
  };

  // Block 2: Logo
  const logoRowId = uuidv4();
  const logoBlock = generateCellByBlockId(blocks, 'Logo', 1);

  const logoRow = {
    id: logoRowId,
    cells: [logoBlock],
  };

  // Block 3: Image + Paragraph + Button
  const ipbRowId = uuidv4();
  const ipbBlock = generateCellByBlockId(blocks, 'Imagem + Parágrafo + Botão', 1);

  const ipbRow = {
    id: ipbRowId,
    cells: [ipbBlock],
  };

  // Block 4: Footer
  const footerRowId = uuidv4();
  const footerBlock = generateCellByBlockId(blocks, 'Rodapé', 1);

  const footerRow = {
    id: footerRowId,
    cells: [footerBlock],
    fixed: true,
  };

  // Template Definition
  const template = {
    id: 3,
    title: 'Cabeçalho + Logo + Imagem + Texto + Botão + Rodapé',
    cardType: 'image',
    image: 'https://config.fanbase.com.br/images/template-builder/template_03.png',
    hideControls: true,
    hideHeader: true,
    content: [
      headerRow,
      logoRow,
      ipbRow,
      footerRow,
    ],
  };

  return template;
};

export default generateTemplateThree;
