<template>
  <vs-card class="create-template-menu">
    <header
      slot="header"
      class="create-template-menu__header"
    >
      <vs-button
        color="primary"
        icon="arrow_back"
        type="flat"
        radius
        @click="goToTemplates"
      />

      <h2>Novo Template</h2>
    </header>

    <main class="pb-3">
      <div class="create-template-menu__content">
        <p class="pl-3 text-secondary">
          {{
            createMode === 'from-template' ?
              'Por favor, escolha um modelo para começar:' :
              'Como você gostaria de criar seu template?'
          }}
        </p>

        <div
          v-if="!createMode"
          class="d-flex ml-3"
          :style="{ gap: '1.75rem' }"
        >
          <vs-card class="create-mode-button">
            <div
              class="create-mode-button__content"
              @click="createMode = null; goToBuilder(blankTemplate);"
            >
              <span class="material-icons-outlined text-secondary">insert_drive_file</span>

              <h2 class="text-primary">
                Modelo em Branco
              </h2>

              <p class="text-secondary">
                Crie um template do zero em nosso editor de "arrastar e soltar".
              </p>
            </div>
          </vs-card>

          <vs-card class="create-mode-button">
            <div
              class="create-mode-button__content"
              @click="createMode = 'from-template'"
            >
              <span class="material-icons-outlined text-secondary">
                space_dashboard
              </span>

              <h2 class="text-primary">
                Escolher Modelo
              </h2>

              <p class="text-secondary">
                Crie um template a partir de um de nossos modelos em nosso editor de "arrastar e soltar".
              </p>
            </div>
          </vs-card>

          <vs-card
            class="create-mode-button"
          >
            <div
              class="create-mode-button__content"
              @click="createMode = null; callImportPopup();"
            >
              <span class="material-icons-outlined text-secondary">
                upload_file
              </span>

              <h2 class="text-primary">
                Importar Modelo
              </h2>

              <p class="text-secondary">
                Faça upload de um novo template (a partir de um arquivo HTML).
              </p>
            </div>
          </vs-card>
        </div>
        <template v-if="createMode === 'from-template'">
          <Carousel
            id="from-template"
            :slides="starterTemplates"
            @select-item="goToBuilder"
          />
        </template>
      </div>
    </main>

    <footer slot="footer" />

    <!-- Popups -->
    <!-- Import Template Popup -->
    <vs-popup
      title="Importar HTML"
      :active.sync="importPopup"
    >
      <div class="import-template-popup">
        <vs-input
          id="template-name"
          v-model="templateName"
          class="mb-4"
          label="Nome do Template"
          name="template-name"
          type="text"
        />

        <!-- Upload File Button -->
        <div class="d-flex align-items-center">
          <div>
            <input
              id="upload-file-input"
              accept=".html"
              class="file-input"
              type="file"
              @change="uploadFile"
            >

            <vs-button icon="file_upload">
              <label
                class="m-0 cursor-pointer"
                for="upload-file-input"
              >
                Procurar...
              </label>
            </vs-button>
          </div>

          <p class="mb-0 ml-3">
            {{ fileName.length > 0 ? fileName : 'Nenhum arquivo selecionado.' }}
          </p>

          <vs-button
            v-if="fileName.length > 0"
            class="ml-1"
            color="dark"
            icon="close"
            type="flat"
            @click="clearFile"
          />
        </div>

        <hr>

        <vs-button
          :disabled="importDisabled"
          icon="upload_file"
          @click="saveTemplateFile"
        >
          Enviar Arquivo
        </vs-button>
      </div>
    </vs-popup>
  </vs-card>
</template>

<script>
/* Components */
import Carousel from './Carousel.vue';

/* Data */
import getBlocks from './TemplateBuilder/data/blocks';

/* Helpers */
import {
  generateBlankTemplate,
  generateTemplateOne,
  generateTemplateTwo,
  generateTemplateThree,
  generateTemplateFour,
} from './StarterTemplates';

/* Services */
const configDomain = process.env.VUE_APP_CONFIGDOMAIN;
import Template from '@/services/template'

const templateService = new Template();

export default {
  name: 'CreateTemplateMenu',
  components: { Carousel },
  data: () => ({
    createMode: null,
    /* Import Template */
    file: {},
    fileName: '',
    templateName: '',
    importDisabled: true,
    /* Popups */
    importPopup: false,
  }),
  computed: {
    /* Store */
    ownerId() {
      return this.$store.state.ownerID;
    },
    /* Templates */
    blocks() {
      const imageSlotUrl = `${configDomain}/images/template-builder/image-slot.png`;
      const logo = this.$store.state.emailTemplateLogo;
      const btnColor = this.$store.state.configBox.bgCardColors;
      const logoWidth = this.$store.state.configBox.logoWidth;
      const ownerCopyright = this.$store.state.ownerCopyright;

      const tokens = {
        btnColor,
        imageSlotUrl,
        logo,
        logoWidth,
        ownerCopyright,
      };

      return getBlocks(tokens);
    },
    blankTemplate() {
      return generateBlankTemplate(this.blocks);
    },
    starterTemplates() {
      let starterTemplates = [];

      // Get pre-designed templates
      const templateOne = generateTemplateOne(this.blocks);
      const templateTwo = generateTemplateTwo(this.blocks);
      const templateThree = generateTemplateThree(this.blocks);
      const templateFour = generateTemplateFour(this.blocks);

      starterTemplates.push(templateOne);
      starterTemplates.push(templateTwo);
      starterTemplates.push(templateThree);
      starterTemplates.push(templateFour);

      return starterTemplates;
    },
    /* Import Template */
    validImport() {
      return this.fileName.length > 0 && this.templateName.length > 0;
    },
  },
  watch: {
    validImport(value) {
      this.importDisabled = !value;
    },
  },
  methods: {
    /* API */
    saveTemplateFile() {
      this.$store.dispatch('set_isLoadingActive', true);

      templateService
        .saveTemplateFile(
          this.ownerId,
          this.file,
          this.templateName,
        )
        .finally(() => {
          this.$store.dispatch('set_isLoadingActive', false);

          this.resetInputs();
          this.goToTemplates();
        });
    },
    /* Helpers */
    clearFile() {
      this.file = {};
      this.fileName = '';
    },
    uploadFile(e) {
      this.file = e.target.files[0];
      this.fileName = e.target.files[0].name;

      if (this.templateName.length === 0) {
        this.templateName = e.target.files[0].name;
      }
    },
    resetInputs() {
      this.clearFile();
      this.templateName = '';
    },
    /* Popups */
    callImportPopup() {
      this.importPopup = true;
    },
    /* Router */
    goToBuilder(selectedTemplate) {
      this.$router.push({
        name: 'TemplateBuilder',
        params: {
          mode: 'create',
          selectedTemplate,
        },
      });
    },
    goToTemplates() {
      if (this.createMode !== null) {
        this.createMode = null;
      } else {
        this.$router.push('/templates');
      }
    },
  },
};
</script>

<style lang="scss">
.create-template-menu {
  padding-bottom: 1rem;

  h2 {
    font-size: 1.35em;
  }

  h3 {
    font-size: 1em;
  }

  .vs-card--content {
    padding: 0;
    margin-bottom: 32px;
  }
}

.create-template-menu__header {
  align-items: center;
  display: flex;
  gap: 0.5rem;
}

.create-template-menu__content {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  padding: 20px;
  width: 100%;
}

.import-template-popup {
  padding: .5rem 0 1rem;

  .file-input {
    display: none;
  }
}

.create-mode-button {
  cursor: pointer;
  height: 250px;
  padding: 2rem;
  text-align: center;
  width: 250px;

  .vs-card--content {
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .create-mode-button__content {
    display: flex;
    flex-flow: column nowrap;
    gap: 1.15rem;
    height: 100%;
    width: 100%;

    h2 {
      font-size: 1.11em;
      text-transform: uppercase;
      white-space: nowrap;
    }
  }
}

.create-mode-button:hover {
  background-color: rgba(var(--vs-primary), .08) !important;

  .create-mode-button__content {
    p {
      color: #4f4f4f !important;
    }
  }
}
</style>
