// Helpers
const { v4: uuidv4 } = require('uuid');
import generateCellByBlockId from '../TemplateBuilder/helpers/generate-cell-by-block-id';

const generateTemplateTwo = (blocks) => {
  // Block 1: Header
  const headerRowId = uuidv4();
  const headerBlock = generateCellByBlockId(blocks, 'Cabeçalho', 1);

  const headerRow = {
    id: headerRowId,
    cells: [headerBlock],
    fixed: true,
  };

  // Block 2: Logo
  const logoRowId = uuidv4();
  const logoBlock = generateCellByBlockId(blocks, 'Logo', 1);

  const logoRow = {
    id: logoRowId,
    cells: [logoBlock],
  };

  // Bloco 3: Paragraph
  const paragraphRowId = uuidv4();
  const paragraphBlock = generateCellByBlockId(blocks, 'Parágrafo', 1);

  const paragraphRow = {
    id: paragraphRowId,
    cells: [paragraphBlock],
  };

  // Block 4: Image + Button
  const imageButtonRowId = uuidv4();
  const imageButtonBlock = generateCellByBlockId(blocks, 'Imagem + Botão', 1);

  const imageButtonRow = {
    id: imageButtonRowId,
    cells: [imageButtonBlock],
  };

  // Block 5: Footer
  const footerRowId = uuidv4();
  const footerBlock = generateCellByBlockId(blocks, 'Rodapé', 1);

  const footerRow = {
    id: footerRowId,
    cells: [footerBlock],
    fixed: true,
  };

  // Template Definition
  const template = {
    id: 2,
    title: 'Cabeçalho + Logo + Imagem + Botão + Rodapé',
    cardType: 'image',
    image: 'https://config.fanbase.com.br/images/template-builder/template_02.png',
    hideControls: true,
    hideHeader: true,
    content: [
      headerRow,
      logoRow,
      paragraphRow,
      imageButtonRow,
      footerRow,
    ],
  };

  return template;
};

export default generateTemplateTwo;
