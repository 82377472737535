// Helpers
const { v4: uuidv4 } = require('uuid');
import appendDataLocator from './append-data-locator';
import translateBlockToHtml from './translate-block-to-html';

const generateCellByBlockId = (
  blocks,
  blockId,
  colspan = 1,
) => {
  const colId = uuidv4();

  const block = blocks.find((b) => b.id === blockId);
  const html = translateBlockToHtml(block.template, block.props);
  const content = appendDataLocator(html, colId);

  const cell = {
    id: colId,
    block,
    content,
    colspan,
    minHeight: block.colMinHeight,
    cellPadding: block.cellPadding,
    style: 'background-color: #FFFFFF; overflow: hidden; vertical-align: top;',
  };

  return cell;
};

export default generateCellByBlockId;
