// Helpers
const { v4: uuidv4 } = require('uuid');
import generateCellByBlockId from '../TemplateBuilder/helpers/generate-cell-by-block-id';

const generateBlankTemplate = (blocks) => {
  // Block 1: Header
  const headerRowId = uuidv4();
  const headerBlock = generateCellByBlockId(blocks, 'Cabeçalho', 1);

  const headerRow = {
    id: headerRowId,
    cells: [headerBlock],
    fixed: true,
  };

  // Block 2: Blank
  const blankRowId = uuidv4();
  const blankCell = generateCellByBlockId(blocks, 'Vazio', 1);

  const blankRow = {
    id: blankRowId,
    cells: [blankCell],
  };

  // Block 3: Footer
  const footerRowId = uuidv4();
  const footerBlock = generateCellByBlockId(blocks, 'Rodapé', 1);

  const footerRow = {
    id: footerRowId,
    cells: [footerBlock],
    fixed: true,
  };

  // Template Definition
  const template = {
    id: 0,
    title: 'Template em branco',
    cardType: 'icon',
    icon: 'add',
    hideControls: true,
    content: [
      headerRow,
      blankRow,
      footerRow,
    ],
  };

  return template;
};

export default generateBlankTemplate;
